import { Component, createResource, ErrorBoundary } from "solid-js";
import { SelectBox } from "~/components/inputs";
import { useItemOrderContext, useSessionContext } from "~/utils/contexts";
import {
  framePayloadGenerator,
  photoFrameSizes,
  galleryFrameSizes,
} from "~/utils/builtFrames";
import { PT } from "~/utils/products";
import { InfoBox } from "~/components/utility";

type PhotoFrameSizeProps = {
  type: PT.PHOTOFRAME | PT.GALLERYFRAME;
};

export const PFGFSize: Component<PhotoFrameSizeProps> = (props) => {
  const { session } = useSessionContext();
  const { orderData, setOrderData } = useItemOrderContext();

  const availableSizeList = props.type ===  PT.PHOTOFRAME ? photoFrameSizes : galleryFrameSizes;

  const [dropdownFramePricing] = createResource(
    () => [orderData.selected.moulding, availableSizeList],
    async ([sku, list]) => {
      // ? to resolve some typescript errors..list is inferring as possibly a string | undefined
      const typedList = list as typeof availableSizeList;

      const codesOnlyList = typedList.map((item) => item.code);

      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/cart/estimate`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${session()?.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              framePayloadGenerator(sku as string, codesOnlyList, props.type)
            ),
          }
        );

        if (response.ok) {
          const data = await response.json();
          const newList = [...typedList].map((item, index) => {
            return {
              ...item,
              price: data[index].Amount.toFixed(2),
            };
          });
          return newList;
        } else {
          // * INDIVIDUALLY REQUEST PRICING FOR PF SIZES
          const res = await response.json();

          // If the initial pricing request (which requests all sizes) fails with an invalid
          // sku error, individually make a request to /estimate for each frame size.
          // This is a stop-gap solution until moulding data contains a list of available sizes.
          // Presently a single list of sizes is maintained, and most mouldings are available in all
          // sizes.
          if (res.Code === "INVALID_SKU") {
            const possibleSizes = availableSizeList.map((item) => ({
              ...item,
              available: false,
              price: undefined,
            }));

            await Promise.allSettled(
              possibleSizes.map(async (item, index) => {
                const res = await fetch(
                  `${import.meta.env.VITE_ROMA_API}/cart/estimate`,
                  {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${session()?.token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(
                      framePayloadGenerator(sku as string, [item.code], props.type)
                    ),
                  }
                );

                if (res.ok) {
                  const data = await res.json();
                  possibleSizes[index].available = true;
                  possibleSizes[index].price = data[0].Amount.toFixed(2);
                }
              })
            );

            const atLeastOneValidSKU = possibleSizes.some(
              (item) => item.available
            );

            if (atLeastOneValidSKU) {
              return possibleSizes.filter((item) => item.available);
            } else {
              throw new Error(
                "Error retrieving pricing for all photo frame sizes"
              );
            }
          } else {
            // If the error was not INVALID_SKU, pass it along to the catch
            throw new Error(`Error Code: ${res.Code}, Message: ${res.Message}`);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    {
      initialValue: [],
      ssrLoadFrom: "initial",
    }
  );

  return (
    <>
      <ErrorBoundary
        fallback={
          <InfoBox type="error">
            Something went wrong while attempting to retrieve pricing / sizing.
            Please call Customer Care at{" "}
            <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
              1-800-263-2322
            </a>{" "}
            for support. We apologize for the inconvenience.
          </InfoBox>
        }
      >
        <SelectBox
        // @ts-expect-error
          options={dropdownFramePricing.latest?.map((item) => ({
            value: item.code,
            leftLabel: (
              <>
                <span>{item.width}"</span>
                <span class="mx-2 text-xs">&#10005;</span>
                <span>{item.height}"</span>
              </>
            ),
            rightLabel: <span>${item.price}</span>,
          }))}
          variantType="justify-between"
          defaultValue={orderData.selected.photoFrame}
          onChange={(option) =>
            setOrderData("selected", props.type, option.value as string)
          }
          loading={dropdownFramePricing.loading}
          label={`Size of ${props.type === PT.PHOTOFRAME ? "Photo" : "Gallery"} Frame`}
          triggerClass="rounded-sm"
          placeholder={`Please select a ${props.type === PT.PHOTOFRAME ? "Photo" : "Gallery"} Frame size`}
        />
      </ErrorBoundary>
    </>
  );
};
