import { Component, Show } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import {
  QtyCounter,
  ComboSizeSelector,
  Sizing,
  Allowance,
  Orientation,
} from "../";

export const Rail: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  return (
    <>
      <div class="flex flex-col gap-3">
        <p class="text-sm font-bold text-roma-dark-grey ">Rail Length</p>
        <Sizing />
        <ComboSizeSelector
          update={(x) => {
            setOrderData("selected", "railLength", x);
          }}
          // label="Rail Length"
          name="rail-length"
          class="w-1/2"
          placeholder="Enter inches"
          initialVal={orderData.selected.railLength}
        />
      </div>

      <Show when={orderData.isStretcher}>
        <Orientation />
      </Show>

      <Allowance />
      <QtyCounter />
    </>
  );
};
