import { Component, onMount, onCleanup } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { Length } from "./Length";

export const Contract:Component<{minFootage: number}> = (props) => {
  const {setOrderData} = useItemOrderContext();

  onMount(()=>{
    setOrderData("selected","linearFt", props.minFootage)
  })
  onCleanup(()=>{
    setOrderData("selected","linearFt", 0)
  })
  return <Length 
    minFootage={props.minFootage}
    label={`Quantity (Linear Feet - Min ${props.minFootage}')`}
  />
}