import { Component, Show, createEffect } from "solid-js";
import { Motion, Presence } from "solid-motionone";
import { useItemOrderContext } from "~/utils/contexts";
import { InfoBox } from "~/components/utility";
import {
  Dimensions,
  Allowance,
  QtyCounter,
  JoinWarning,
  WedgeLock,
  CalculatedDimensions,
} from "../";
import { PT } from "~/utils/products";
import { OversizedJoinWarning } from "./Join";

export const Stacked: Component = (props) => {
  const { orderData, setOrderData } = useItemOrderContext();
  createEffect(() => {
    if (orderData.selected.productType?.includes("Join")) {
      setOrderData("selected", "stackOperation", PT.JOIN);
    }
    if (orderData.selected.productType?.includes("Chop")) {
      setOrderData("selected", "stackOperation", PT.CHOP);
    }
    if (orderData.selected.productType?.includes("Mirror")) {
      setOrderData("selected", "stackOperation", PT.MIRROR);
    }
  });
  return (
    <>
      <Show
        when={
          (orderData.selected.stackCount == 2 &&
            orderData.selected.stacks.outsideDetails.sku &&
            orderData.selected.stacks.insideDetails.sku &&
            !orderData.selected.stacks.outsideDetails.floater) ||
          (orderData.selected.stackCount == 3 &&
            orderData.selected.stacks.outsideDetails.sku &&
            orderData.selected.stacks.middleDetails.sku &&
            orderData.selected.stacks.insideDetails.sku &&
            !orderData.selected.stacks.outsideDetails.floater &&
            !orderData.selected.stacks.middleDetails.floater)
        }
        fallback={
          <InfoBox>
            <p>Select all mouldings to continue</p>
          </InfoBox>
        }
      >
        <Dimensions
          insideText="Inside Stacked Frame"
          outsideText="Outside Stacked Frame"
        />
        <Show when={orderData.selected.oversizeWarning}>
          <OversizedJoinWarning />
        </Show>
        <JoinWarning />
        <Show
          when={orderData.selected.width && orderData.selected.height}
          fallback={
            <InfoBox>
              <p>Specify dimensions to continue.</p>
            </InfoBox>
          }
        >
          <Motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, transition: { duration: 0.7 } }}
            transition={{ duration: 0.5, easing: "ease-in-out" }}
          >
            <CalculatedDimensions />
          </Motion.div>
          <Allowance />
          <Show when={orderData.selected.stackOperation == PT.CHOP}>
            <WedgeLock />
          </Show>
          <QtyCounter />
        </Show>
      </Show>
    </>
  );
};
