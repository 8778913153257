import { Component, onMount } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { StackProductSearch } from "./StackProductSearch";

export const StackSearchTwo: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();

  onMount(() => {
    if (
      orderData.selected.moulding !== undefined &&
      orderData.selected.stacks.insideDetails.sku === undefined
      // && orderData.width
    ) {
      setOrderData(
        "selected",
        "stacks",
        "insideDetails",
        "sku",
        orderData.selected.moulding
      );
      setOrderData(
        "selected",
        "stacks",
        "insideDetails",
        "mouldingWidth",
        orderData.width
      );
    }
  });

  // const ogOutside = orderData.selected.stacks.outsideDetails.sku;
  // const ogInside = orderData.selected.stacks.insideDetails.sku;

  // const handleSwap = (inside, outside) => {
  //   let originalInside = inside;
  //   let originalOutside = outside;

  //   setOrderData("selected", "stacks", "outsideDetails", "sku", originalInside);
  //   setOrderData("selected", "stacks", "insideDetails", "sku", originalOutside);
  // };

  return (
    <>
      <StackProductSearch
        label="Inside Frame"
        position="inside"
        preloadSku={orderData.selected.stacks.insideDetails.sku ?? undefined}
        storePath={["selected", "stacks", "insideDetails"]}
        // ! swap causing issues since StackInventorySelector implemented -> readdress
        // showSwap={orderData.selected.stacks.outsideDetails.sku && orderData.selected.stacks.insideDetails.sku}
        // swapFunction={()=>handleSwap(orderData.selected.stacks.insideDetails.sku, orderData.selected.stacks.outsideDetails.sku)}
      />
      {/* <Show when={false}>
        <div class="border border-orange-500 p-4 flex justify-center items-center">
        <button class="border border-orange-500 px-3 py-2" onClick={()=>{handleSwap(orderData.selected.stacks.insideDetails.sku, orderData.selected.stacks.outsideDetails.sku)}}>SWAP</button>
        </div>
      </Show> */}
      <StackProductSearch
        label="Outside Frame"
        position="outside"
        preloadSku={orderData.selected.stacks.outsideDetails.sku ?? undefined}
        storePath={["selected", "stacks", "outsideDetails"]}
        // showSwap={orderData.selected.stacks.outsideDetails.sku && orderData.selected.stacks.insideDetails.sku}
        // swapFunction={()=>handleSwap(orderData.selected.stacks.insideDetails.sku, orderData.selected.stacks.outsideDetails.sku)}
      />
    </>
  );
};

export default StackSearchTwo;
