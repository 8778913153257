import { LineItem,
  LengthLineItem, //L
  ContractLineItem,  //CT
  JoinLineItem,  //J
  RailLineItem,  //R
  ChopLineItem, //C
  MirrorLineItem, //M
  PhotoFrameLineItem, //PF
  GalleryFrameLineItem, //GF
  CornerSampleLineItem, //CS
  StackLineItem, //ST
  BoxLineItem // B
} from "~/services/roma-api/cart/types-lineItems";
import { PT } from "~/utils/products";


export const isStackLineItem = (
  line: LineItem
) : line is StackLineItem => {
  return line.Type === PT.STACK;
}


export const cartTypeguard = {
  isStackLineItem
}