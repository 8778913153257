import {
  OrderProductTypes,
  LengthProduct,
  ContractProduct,
  JoinProduct,
  RailProduct,
  ChopProduct,
  MirrorProduct,
  PhotoFrameProduct,
  GalleryFrameProduct,
  CornerSampleProduct,
  StackProduct, 
  BoxProduct
} from "~/services/roma-api/cart/types-productOrdering";
import { PT } from "~/utils/products";


export const isLengthProduct = (
  line: Partial<OrderProductTypes>
): line is LengthProduct => {
  return line.Type === PT.LENGTH;
};
export const isContractProduct = (
  line: Partial<OrderProductTypes>
): line is ContractProduct => {
  return line.Type === PT.CONTRACT;
};

export const isJoinProduct = (
  line: Partial<OrderProductTypes>
): line is JoinProduct => {
  return line.Type === PT.JOIN;
};
export const isRailProduct = (
  line: Partial<OrderProductTypes>
): line is RailProduct => {
  return line.Type === PT.RAIL;
};
export const isChopProduct = (
  line: Partial<OrderProductTypes>
): line is ChopProduct => {
  return line.Type === PT.CHOP;
};
export const isMirrorProduct = (
  line: Partial<OrderProductTypes>
): line is MirrorProduct => {
  return line.Type === PT.MIRROR;
};
export const isPhotoFrameProduct = (
  line: Partial<OrderProductTypes>
): line is PhotoFrameProduct => {
  return line.Type === PT.PHOTOFRAME;
};
export const isGalleryFrameProduct = (
  line: Partial<OrderProductTypes>
): line is GalleryFrameProduct => {
  return line.Type === PT.GALLERYFRAME;
};
export const isBoxProduct = (
  line: Partial<OrderProductTypes>
): line is BoxProduct => {
  return line.Type === PT.BOX;
};
export const isCornerSampleProduct = (
  line: Partial<OrderProductTypes>
) : line is CornerSampleProduct => {
  return line.Type === PT.CORNERSAMPLE
}
export const isStackProduct = (
  line: Partial<OrderProductTypes>
) : line is StackProduct => {
  return line.Type === PT.STACK || !!line.Type?.includes(PT.STACK)
}

export const orderTypeguard = {
  isLengthProduct,
  isContractProduct,
  isJoinProduct,
  isRailProduct,
  isChopProduct,
  isMirrorProduct,
  isPhotoFrameProduct,
  isGalleryFrameProduct,
  isBoxProduct,
  isCornerSampleProduct,
  isStackProduct,
}