import { Component } from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { useItemOrderContext } from "~/utils/contexts";
import { TextFieldInput } from "~/components/inputs";

export const TagName: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();

  const setTag = debounce((val: string) => {
    setOrderData("selected", "tagName", val);
  }, 500);

  return (
    <div>
      <TextFieldInput
        label="Tag Item (optional)"
        defaultValue={orderData.selected.tagName}
        onChange={setTag}
        maxLength={35}
        placeholder="Enter a short name to identify the item on your order (35 characters max)"
        class="!rounded-sm text-sm text-roma-dark-grey"
        labelClass="text-roma-dark-grey"
      />
    </div>
  );
};
