import { Component, onCleanup, createRenderEffect } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { QtyCounter, PFGFSize } from "../";
import { PT } from "~/utils/products";
// import PhotoFrameSize from "../comps/PhotoFrameSize";

export const PhotoFrame: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  createRenderEffect(() => {
    if (!orderData.editProduct) setOrderData("selected", "quantity", 2);
  });
  onCleanup(() => {
    setOrderData("selected", "quantity", 1);
  });

  return (
    <>
      <PFGFSize type={PT.PHOTOFRAME} />
      <QtyCounter />
      <p class="text-sm text-roma-dark-grey font-light">
        &bull; Minimum quantity of 2 photo frames required
      </p>
    </>
  );
};
