import { Component, Show } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";

export const DiscontinuedStackWarning: Component = () => {
  const { orderData } = useItemOrderContext();

  return (
    <div class="border bg-orange-50 p-3 flex items-center text-sm text-roma-dark-grey">
      <p class="ml-3">
        <Show
          when={orderData.shipsFromLength === 1}
          fallback={
            <span>
              There is no stock for one or more of the moulings in your stacked
              frame at your selected distribution center, please select another
              DC with stock to proceed.
            </span>
          }
        >
          <span>
            There is no stock for one or more of the moulings in your stacked
            frame. Please choose another moulding to proceed.
          </span>
        </Show>
      </p>
    </div>
  );
};
