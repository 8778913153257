export const getDefaultStore = () => {
  return {
    blockCheckout: false,
    editProduct: false,
    defaultShipsFrom: undefined,
    shipsFromLength: undefined,
    selected: {
      moulding: undefined, // sku
      mouldingWidth: undefined,
      name: undefined,
      category: undefined,
      collection: undefined,
      stacked: false,
      shipsFrom: undefined,
      shipsFromPlant: "",
      shipsFromStockLevel: undefined,
      backorderConsent: undefined,
      showBackorderBox: false,
      showCancelBackorderBox: false,
      cancelBackorderConsent: undefined,
      freightChargeConsent: undefined,
      showFreightBox: false,
      oversizeWarning: false,
      productType: undefined, //Length, Join, Chop, etc..
      linearFt: undefined, //only if productType = Length
      cuttingInstr: undefined, // either defined in a list, or 'other'}
      specialCuttingInstr: undefined,
      width: undefined,
      height: undefined,
      sizing: undefined,
      orientation: "standard",
      tagName: undefined,
      allowance: undefined,
      wedgeLock: undefined,
      glassType: undefined,
      railLength: undefined,
      photoFrame: undefined,
      galleryFrame: undefined,
      quantity: 1,
      amount: undefined,
      validDimensions: undefined,
      stackCount: undefined,
      stackOperation: undefined,
      stackContainsZeroStockItem: undefined,
      stackStockCheckoutStatus: undefined,
      stacks: {
        outside: undefined,
        outsideDetails: {
          sku: undefined,
          mouldingWidth: undefined,
          mouldingHeight: undefined,
          width: undefined,
          height: undefined,
          floater: undefined,
          fillet: undefined,
          discontinued: undefined,
          stockLevel: undefined,
          category: undefined,
        },
        middle: undefined,
        middleDetails: {
          sku: undefined,
          mouldingWidth: undefined,
          mouldingHeight: undefined,
          width: undefined,
          height: undefined,
          floater: undefined,
          fillet: undefined,
          discontinued: undefined,
          stockLevel: undefined,
          category: undefined,
        },
        insideDetails: {
          sku: undefined,
          mouldingWidth: undefined,
          mouldingHeight: undefined,
          width: undefined,
          height: undefined,
          floater: undefined,
          fillet: undefined,
          discontinued: undefined,
          stockLevel: undefined,
          category: undefined,
        },
      },
    },
  } as const;
};