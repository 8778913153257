import {
  createResource,
  Show,
  createMemo,
  Component,
  createSignal,
} from "solid-js";
import { produce } from "solid-js/store";
import { useNavigate, useSearchParams, useSubmission } from "@solidjs/router";
import Button from "~/components/Button";
import { BaseLoader } from "~/components/utility";
import {
  useItemOrderContext,
  useSiteContext,
  useSessionContext,
  useErrorContext,
} from "~/utils/contexts";
import { decimalString } from "~/utils/fraction";
import { PT } from "~/utils/products";
import { InfoBox } from "~/components/utility";

import { EventType } from "@solid-primitives/analytics";
import { stockThreshold, ThresholdCheckout } from "~/utils/threshold";
import {
  minWidthForDoubleWedge,
  minWidthForSingleWedge,
  isJoinOversized,
  frameDictionary,
} from "~/utils/builtFrames";

import { getDefaultStore } from "./utils";
//! import { animateCart } from "~/utils/misc";
import { OrderStore } from "~/routes/(layout)/order/(order)";
import {
  CUTTING,
  CuttingOption,
  OrderProductTypes,
  WEDGE,
  WedgeOption,
} from "~/services/roma-api/cart/types-productOrdering";

import { trackFBQ } from "~/utils/analytics";
import { typeguard } from "~/utils/typeguard";

import { addToCartAction, editCartItemAction } from "~/services/cart";
import { useAction } from "@solidjs/router";
import {
  APIError,
  checkError,
  simulateApiError,
} from "~/services/roma-api/errors";
import { fetchAPI2 } from "~/services/roma-api/fetchAPI2";

type DeepNonNullable<T> = T extends object
  ? {
      [P in keyof T]: DeepNonNullable<NonNullable<T[P]>>;
    }
  : NonNullable<T>;

export const OrderSummary: Component = () => {
  const { session } = useSessionContext();
  const { track, panel, setPanel } = useSiteContext();
  const { orderData: storeData, setOrderData } = useItemOrderContext();
  const { addError } = useErrorContext();
  // ! ENSURE THIS DOESN'T BREAK REACTIVITY:
  const orderData = storeData as DeepNonNullable<OrderStore>;

  const [cartError, setCartError] = createSignal<string>();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  // ADD / EDIT CART (TESTING)
  // TODO - NOT TESTING ANYMORE!!!
  const addItemToCart = useAction(addToCartAction);
  const addingToCart = useSubmission(addToCartAction);

  const editLineItem = useAction(editCartItemAction);
  const editingItem = useSubmission(editCartItemAction);

  const aCartActionIsPending = () =>
    addingToCart.pending || editingItem.pending;

  const orderQtyInFeet = createMemo(() => {
    if (
      !orderData.selected.productType ||
      !orderData.selected.shipsFromStockLevel
    )
      return 0;

    const totalInches = () => {
      const mwidth = orderData.width;

      switch (orderData.selected.productType) {
        case PT.LENGTH:
          return orderData.selected.linearFt * 12;
        case PT.CHOP:
        case PT.JOIN:
          return (
            (orderData.selected.width * 2 +
              orderData.selected.height * 2 +
              (orderData.selected.sizing === "I" ? mwidth * 4 : 0)) *
            orderData.selected.quantity
          );
        case PT.RAIL:
          return (
            (orderData.selected.railLength +
              (orderData.selected.sizing === "I" ? mwidth * 2 : 0)) *
            orderData.selected.quantity
          );
        default:
          return 0;
      }
    };

    return Math.ceil(totalInches() / 12);
  });

  const item = createMemo<OrderProductTypes | boolean>(() => {
    const line: Partial<OrderProductTypes> = {
      Type: undefined,
      Quantity: orderData.selected.quantity,
      Plant: orderData.selected.shipsFromPlant,
      BackOrder: orderData.selected.backorderConsent,
      CancelBackOrder: orderData.selected.cancelBackorderConsent,
      FreightAccepted:
        orderData.selected.freightChargeConsent === true ? true : undefined,
      Oversized: orderData.selected.freightChargeConsent,
      Tag: orderData.selected.tagName,
      SubItems: [
        {
          SKU: orderData.selected.moulding,
          Moulding: orderData.selected.moulding,
          Length: undefined,
          Width: undefined,
          Layer: undefined,
          LengthFraction: undefined,
          WidthFraction: undefined,
        },
      ],
    } as Partial<OrderProductTypes>;

    setOrderData(
      produce((store) => {
        store.selected.showBackorderBox = false;
        store.selected.showCancelBackorderBox = false;
        store.selected.showFreightBox = false;
        store.selected.oversizeWarning = false;
        store.blockCheckout = false;
      })
    );

    if (orderData.selected.productType == PT.LENGTH) {
      line.Type = PT.LENGTH;
      // * Type guard
      if (typeguard.order.isLengthProduct(line)) {
        if (
          isNaN(orderData.selected.linearFt) ||
          !orderData.selected.linearFt
        ) {
          return true;
        }
        line.SubItems[0].Length = orderData.selected.linearFt;
        line.Quantity = 1;
        if (!orderData.selected.cuttingInstr) {
          return true;
        } else if (orderData.selected.cuttingInstr == CUTTING.OTHER) {
          line.Cutting = orderData.selected
            .specialCuttingInstr as CuttingOption;
          // ? Technically specialCuttingInstr is not one of the CuttingOptions.
          // ? For next refactor, API could accept an *additional* property `CuttingOther` with the note,
          // ? and still pass Cutting: 'other'
        } else {
          line.Cutting = orderData.selected.cuttingInstr;
        }
      }
    } else if (orderData.selected.productType == PT.CONTRACT) {
      line.Type = PT.CONTRACT;

      if (typeguard.order.isContractProduct(line)) {
        if (
          isNaN(orderData.selected.linearFt) ||
          orderData.selected.linearFt < 3000
        ) {
          return true;
        }
        line.SubItems[0].Length = orderData.selected.linearFt;
      }
    } else if (orderData.selected.productType == PT.JOIN) {
      line.Type = PT.JOIN;
      if (typeguard.order.isJoinProduct(line)) {
        if (
          orderData.selected.height <= 0 ||
          orderData.selected.width <= 0 ||
          !orderData.selected.height ||
          !orderData.selected.width ||
          (orderData.selected.sizing === "I" &&
            !orderData.selected.allowance) ||
          !orderData.selected.validDimensions
        ) {
          return true;
        }
        if (orderData.currentProduct.Category === "Roma Elite") {
          if (!orderData.selected.freightChargeConsent) {
            return true;
          }
        }
        // Stretcher mouldings can be sold standard or flipped as a 'gallery wrap'
        if (orderData.isStretcher) {
          line.SubItems[0].Orientation = orderData.selected.orientation;
        }

        // Check if join is considered oversized for ground shipping
        const oversized = isJoinOversized(
          orderData.selected.width,
          orderData.selected.height,
          orderData.selected.sizing,
          orderData.width,
          orderData.mouldingHeight
        );

        if (oversized) {
          setOrderData("selected", "oversizeWarning", true);
        }

        line.Allowance =
          orderData.selected.sizing === "I"
            ? orderData.selected.allowance
            : undefined;
        line.SubItems[0].Layer = orderData.selected.sizing;
        line.SubItems[0].Length = Math.trunc(orderData.selected.height);
        line.SubItems[0].Width = Math.trunc(orderData.selected.width);
        if (orderData.selected.height % 1 !== 0) {
          line.SubItems[0].LengthFraction = decimalString(
            orderData.selected.height
          );
        }
        if (orderData.selected.width % 1 !== 0) {
          line.SubItems[0].WidthFraction = decimalString(
            orderData.selected.width
          );
        }
      }
    } else if (orderData.selected.productType == PT.RAIL) {
      line.Type = PT.RAIL;
      if (typeguard.order.isRailProduct(line)) {
        // !HERE
        if (
          orderData.selected.railLength <= 0 ||
          !orderData.selected.railLength ||
          !orderData.selected.allowance
        ) {
          return true;
        }

        line.Allowance = orderData.selected.allowance;
        line.SubItems[0].Layer = orderData.selected.sizing;
        line.SubItems[0].Length = Math.trunc(orderData.selected.railLength);
        if (orderData.selected.railLength % 1 !== 0) {
          line.SubItems[0].LengthFraction = decimalString(
            orderData.selected.railLength
          );
        }

        if (orderData.isStretcher) {
          line.SubItems[0].Orientation = orderData.selected.orientation;
        }
      }
    } else if (orderData.selected.productType == PT.CHOP) {
      line.Type = PT.CHOP;
      if (typeguard.order.isChopProduct(line)) {
        if (
          orderData.selected.height <= 0 ||
          orderData.selected.width <= 0 ||
          !orderData.selected.height ||
          !orderData.selected.width ||
          (orderData.selected.sizing === "I" &&
            !orderData.selected.allowance) ||
          !orderData.selected.wedgeLock ||
          !orderData.selected.validDimensions
        ) {
          return true;
        }

        // Stretcher mouldings can be sold standard or flipped as a 'gallery wrap'
        if (orderData.isStretcher) {
          line.SubItems[0].Orientation = orderData.selected.orientation;
        }

        line.Allowance =
          orderData.selected.sizing === "I"
            ? orderData.selected.allowance
            : undefined;

        line.SubItems[0].Wedge =
          orderData.selected.wedgeLock == "none"
            ? undefined
            : (orderData.selected.wedgeLock as WedgeOption);
        line.SubItems[0].Layer = orderData.selected.sizing;
        line.SubItems[0].Length = Math.trunc(orderData.selected.height);
        line.SubItems[0].Width = Math.trunc(orderData.selected.width);
        if (orderData.selected.height % 1 !== 0) {
          line.SubItems[0].LengthFraction = decimalString(
            orderData.selected.height
          );
        }
        if (orderData.selected.width % 1 !== 0) {
          line.SubItems[0].WidthFraction = decimalString(
            orderData.selected.width
          );
        }
      }
    } else if (orderData.selected.productType == PT.MIRROR) {
      line.Type = PT.MIRROR;
      if (typeguard.order.isMirrorProduct(line)) {
        // return true;
        if (
          orderData.selected.height <= 0 ||
          orderData.selected.width <= 0 ||
          !orderData.selected.height ||
          !orderData.selected.width ||
          !orderData.selected.glassType ||
          !orderData.selected.validDimensions
        ) {
          return true;
        }

        line.Bevel = orderData.selected.glassType;
        line.SubItems[0].Layer = orderData.selected.sizing;
        line.SubItems[0].Length = Math.trunc(orderData.selected.height);
        line.SubItems[0].Width = Math.trunc(orderData.selected.width);
        if (orderData.selected.height % 1 !== 0) {
          line.SubItems[0].LengthFraction = decimalString(
            orderData.selected.height
          );
        }
        if (orderData.selected.width % 1 !== 0) {
          line.SubItems[0].WidthFraction = decimalString(
            orderData.selected.width
          );
        }
      }
    } else if (orderData.selected.productType == PT.PHOTOFRAME) {
      line.Type = PT.PHOTOFRAME;
      if (typeguard.order.isPhotoFrameProduct(line)) {
        if (!orderData.selected.photoFrame) {
          return true;
        }
        if (orderData.selected.quantity < 2) {
          return true;
        }
        // line.Plant = orderData.selected.photoFramePlant;
        line.SubItems[0].SKU += `-${orderData.selected.photoFrame}`;

        // Destructure code to determine frame size and add to FrameSize property on line, need for display on email templates:
        line.FrameSize = {
          Width: frameDictionary[orderData.selected.photoFrame].width,
          Length: frameDictionary[orderData.selected.photoFrame].height,
        };
      }
    } else if (orderData.selected.productType == PT.GALLERYFRAME) {
      line.Type = PT.GALLERYFRAME;
      if (typeguard.order.isGalleryFrameProduct(line)) {
        if (!orderData.selected.galleryFrame) {
          return true;
        }
        if (orderData.selected.quantity < 6) {
          return true;
        }

        line.SubItems[0].SKU += `GF-${orderData.selected.galleryFrame}M1`;

        // Destructure code to determine frame size and add to FrameSize property on line, need for display on email templates:
        line.FrameSize = {
          Width: frameDictionary[orderData.selected.galleryFrame].width,
          Length: frameDictionary[orderData.selected.galleryFrame].height,
        };
      }
    } else if (orderData.selected.productType == PT.CORNERSAMPLE) {
      line.Type = PT.CORNERSAMPLE;
      if (typeguard.order.isCornerSampleProduct(line)) {
        line.SubItems[0].SKU += `-COR`;
      }
    } else if (orderData.selected.productType == PT.BOX) {
      line.Type = PT.BOX;
      if (typeguard.order.isBoxProduct(line)) {
        line.SubItems[0].Length = 1;
      }
    } else if (orderData.selected.productType.includes(PT.STACK)) {
      line.Type = PT.STACK;

      if (typeguard.order.isStackProduct(line)) {
        if (orderData.selected.stackOperation === PT.JOIN) {
          line.Operation = PT.JOIN;
        } else if (orderData.selected.stackOperation === PT.CHOP) {
          line.Operation = PT.CHOP;
          if (!orderData.selected.wedgeLock) return true;
          //DELETE line.Wedge = orderData.selected.wedgeLock;
        } else if (orderData.selected.productType.includes(PT.MIRROR)) {
          return true;
          // line.Operation = "mirror";
        }

        if (!orderData.selected.allowance) {
          return true;
        }
        line.Allowance = orderData.selected.allowance;

        // ! check that values for inside/outside are not 0 or null - might need to add more checks or clean this up
        if (
          orderData.selected.stacks.outsideDetails.height <= 0 ||
          orderData.selected.stacks.outsideDetails.width <= 0 ||
          orderData.selected.stacks.insideDetails.height <= 0 ||
          orderData.selected.stacks.insideDetails.width <= 0 ||
          !orderData.selected.height ||
          !orderData.selected.width ||
          !orderData.selected.stacks.outsideDetails.sku ||
          !orderData.selected.stacks.insideDetails.sku ||
          !orderData.selected.validDimensions
        ) {
          return true;
        }

        // ! Set the users choice (inside, outside, and the dimensions they entered *not the calculated dimensions) - this is used for UI and Internal Notes:
        line.FrameSize = {
          Sizing: orderData.selected.sizing == "O" ? "outside" : "inside",
          Width: Math.trunc(orderData.selected.width),
          Length: Math.trunc(orderData.selected.height),
          WidthFraction: decimalString(orderData.selected.width),
          LengthFraction: decimalString(orderData.selected.height),
          Layers: {
            O: orderData.selected.stacks.outsideDetails.sku,
            M2: "",
            I: orderData.selected.stacks.insideDetails.sku,
          },
        };

        // ! set the first subItem (OUTSIDE)
        line.SubItems[0].SKU = orderData.selected.stacks.outsideDetails.sku;
        line.SubItems[0].Moulding =
          orderData.selected.stacks.outsideDetails.sku;
        line.SubItems[0].Layer = "O";
        line.SubItems[0].Length = Math.trunc(
          orderData.selected.stacks.outsideDetails.height
        );
        line.SubItems[0].Width = Math.trunc(
          orderData.selected.stacks.outsideDetails.width
        );
        if (orderData.selected.stacks.outsideDetails.height % 1 !== 0) {
          line.SubItems[0].LengthFraction = decimalString(
            orderData.selected.stacks.outsideDetails.height
          );
        }
        if (orderData.selected.stacks.outsideDetails.width % 1 !== 0) {
          line.SubItems[0].WidthFraction = decimalString(
            orderData.selected.stacks.outsideDetails.width
          );
        }
        // * Determining / setting first layer's WEDGE (2&3 STACK / CHOP)
        if (
          orderData.selected.wedgeLock === "TRUE" &&
          orderData.selected.stackOperation === PT.CHOP
        ) {
          if (
            orderData.selected.stacks.outsideDetails.mouldingWidth >=
            minWidthForDoubleWedge
          ) {
            line.SubItems[0].Wedge = WEDGE.DOUBLE;
          } else if (
            orderData.selected.stacks.outsideDetails.mouldingWidth >
            minWidthForSingleWedge
          ) {
            line.SubItems[0].Wedge = WEDGE.SINGLE;
          }
        }
        // * END Determining / setting first layer's WEDGE (2&3 STACK / CHOP)

        // * 3 LAYER STACK
        if (orderData.selected.stackCount == 3) {
          // ! if 3 stack, check that data is not 0 or null
          if (
            orderData.selected.stacks.middleDetails.height <= 0 ||
            orderData.selected.stacks.middleDetails.width <= 0 ||
            !orderData.selected.stacks.middleDetails.height ||
            !orderData.selected.stacks.middleDetails.width ||
            !orderData.selected.stacks.middleDetails.sku
          ) {
            return true;
          }

          let middleWedge;
          if (
            orderData.selected.wedgeLock === "TRUE" &&
            orderData.selected.stackOperation === PT.CHOP
          ) {
            if (
              orderData.selected.stacks.middleDetails.mouldingWidth >=
              minWidthForDoubleWedge
            ) {
              middleWedge = WEDGE.DOUBLE;
            } else if (
              orderData.selected.stacks.middleDetails.mouldingWidth >
              minWidthForSingleWedge
            ) {
              middleWedge = WEDGE.SINGLE;
            }
          }

          // ! if 3 stack, push line item into SubItems and add sku to Line.FrameSize.Layers.M2
          line.SubItems.push({
            SKU: orderData.selected.stacks.middleDetails.sku,
            Moulding: orderData.selected.stacks.middleDetails.sku,
            Layer: "2",
            Length: Math.trunc(orderData.selected.stacks.middleDetails.height),
            Width: Math.trunc(orderData.selected.stacks.middleDetails.width),
            LengthFraction:
              orderData.selected.stacks.middleDetails.height % 1 !== 0
                ? decimalString(orderData.selected.stacks.middleDetails.height)
                : undefined,
            WidthFraction:
              orderData.selected.stacks.middleDetails.width % 1 !== 0
                ? decimalString(orderData.selected.stacks.middleDetails.width)
                : undefined,
            Wedge: middleWedge,
          });

          line.FrameSize.Layers.M2 =
            orderData.selected.stacks.middleDetails.sku;
        }
        // * END 3 LAYER STACK

        // * Check if join is considered oversized for ground shipping
        if (orderData.selected.stackOperation === PT.JOIN) {
          const oversized = isJoinOversized(
            orderData.selected.stacks.outsideDetails.width,
            orderData.selected.stacks.outsideDetails.height,
            orderData.selected.sizing,
            orderData.selected.stacks.outsideDetails.mouldingWidth,
            orderData.selected.stacks.outsideDetails.mouldingHeight
          );

          if (oversized) {
            setOrderData("selected", "oversizeWarning", true);
          }
        }

        // * Determining inside layer's WEDGE (2&3 STACK / CHOP)
        let insideWedge;
        if (
          orderData.selected.wedgeLock === "TRUE" &&
          orderData.selected.stackOperation === PT.CHOP
        ) {
          if (
            orderData.selected.stacks.insideDetails.mouldingWidth >=
            minWidthForDoubleWedge
          ) {
            insideWedge = WEDGE.DOUBLE;
          } else if (
            orderData.selected.stacks.insideDetails.mouldingWidth >
            minWidthForSingleWedge
          ) {
            insideWedge = WEDGE.SINGLE;
          }
        }
        // * END Determining inside layer's WEDGE (2&3 STACK / CHOP)
        // ! push inside moulding as last item in SubItems
        line.SubItems.push({
          SKU: orderData.selected.stacks.insideDetails.sku,
          Moulding: orderData.selected.stacks.insideDetails.sku,
          Layer: "I",
          Length: Math.trunc(orderData.selected.stacks.insideDetails.height),
          Width: Math.trunc(orderData.selected.stacks.insideDetails.width),
          LengthFraction:
            orderData.selected.stacks.insideDetails.height % 1 !== 0
              ? decimalString(orderData.selected.stacks.insideDetails.height)
              : undefined,
          WidthFraction:
            orderData.selected.stacks.insideDetails.width % 1 !== 0
              ? decimalString(orderData.selected.stacks.insideDetails.width)
              : undefined,
          Wedge: insideWedge,
        });

        if (orderData.selected.sizing == "I") {
          line.SubItems.reverse();
        }
      }

      // ?? ORIGINAL STACK LOGIC - KEEP UNTIL TESTED ABOVE
      // if (orderData.selected.stackOperation === JOIN) {
      //   line.Operation = "join";
      // } else if (orderData.selected.stackOperation === CHOP) {
      //   line.Operation = "chop";
      //   if (!orderData.selected.wedgeLock) return true;
      //   //DELETE line.Wedge = orderData.selected.wedgeLock;
      // } else if (orderData.selected.productType.includes(MIRROR)) {
      //   return true;
      //   // line.Operation = "mirror";
      // }

      // if (!orderData.selected.allowance) {
      //   return true;
      // }
      // line.Allowance = orderData.selected.allowance;

      // // ! check that values for inside/outside are not 0 or null - might need to add more checks or clean this up
      // if (
      //   orderData.selected.stacks.outsideDetails.height <= 0 ||
      //   orderData.selected.stacks.outsideDetails.width <= 0 ||
      //   orderData.selected.stacks.insideDetails.height <= 0 ||
      //   orderData.selected.stacks.insideDetails.width <= 0 ||
      //   !orderData.selected.height ||
      //   !orderData.selected.width ||
      //   !orderData.selected.stacks.outsideDetails.sku ||
      //   !orderData.selected.stacks.insideDetails.sku ||
      //   !orderData.selected.validDimensions
      // ) {
      //   return true;
      // }

      // // ! Set the users choice (inside, outside, and the dimensions they entered *not the calculated dimensions) - this is used for UI and Internal Notes:
      // line.FrameSize = {
      //   Sizing: orderData.selected.sizing == "O" ? "outside" : "inside",
      //   Width: Math.trunc(orderData.selected.width),
      //   Length: Math.trunc(orderData.selected.height),
      //   WidthFraction: decimalString(orderData.selected.width),
      //   LengthFraction: decimalString(orderData.selected.height),
      //   Layers: {
      //     O: orderData.selected.stacks.outsideDetails.sku,
      //     M2: "",
      //     I: orderData.selected.stacks.insideDetails.sku,
      //   },
      // };

      // // ! set the first subItem (OUTSIDE)
      // line.SubItems[0].SKU = orderData.selected.stacks.outsideDetails.sku;
      // line.SubItems[0].Moulding = orderData.selected.stacks.outsideDetails.sku;
      // line.SubItems[0].Layer = "O";
      // line.SubItems[0].Length = Math.trunc(
      //   orderData.selected.stacks.outsideDetails.height
      // );
      // line.SubItems[0].Width = Math.trunc(
      //   orderData.selected.stacks.outsideDetails.width
      // );
      // if (orderData.selected.stacks.outsideDetails.height % 1 !== 0) {
      //   line.SubItems[0].LengthFraction = decimalString(
      //     orderData.selected.stacks.outsideDetails.height
      //   );
      // }
      // if (orderData.selected.stacks.outsideDetails.width % 1 !== 0) {
      //   line.SubItems[0].WidthFraction = decimalString(
      //     orderData.selected.stacks.outsideDetails.width
      //   );
      // }
      // if (
      //   orderData.selected.wedgeLock === "TRUE" &&
      //   orderData.selected.stackOperation === CHOP
      // ) {
      //   if (
      //     orderData.selected.stacks.outsideDetails.mouldingWidth >=
      //     minWidthForDoubleWedge
      //   ) {
      //     line.SubItems[0].Wedge = "double";
      //   } else if (
      //     orderData.selected.stacks.outsideDetails.mouldingWidth >
      //     minWidthForSingleWedge
      //   ) {
      //     line.SubItems[0].Wedge = "single";
      //   }
      // }

      // // ! if 3 stack, check that data is not 0 or null
      // if (orderData.selected.stackCount == 3) {
      //   if (
      //     orderData.selected.stacks.middleDetails.height <= 0 ||
      //     orderData.selected.stacks.middleDetails.width <= 0 ||
      //     !orderData.selected.stacks.middleDetails.height ||
      //     !orderData.selected.stacks.middleDetails.width ||
      //     !orderData.selected.stacks.middleDetails.sku
      //   ) {
      //     return true;
      //   }

      //   let middleWedge;
      //   if (
      //     orderData.selected.wedgeLock === "TRUE" &&
      //     orderData.selected.stackOperation === CHOP
      //   ) {
      //     if (
      //       orderData.selected.stacks.middleDetails.mouldingWidth >=
      //       minWidthForDoubleWedge
      //     ) {
      //       middleWedge = "double";
      //     } else if (
      //       orderData.selected.stacks.middleDetails.mouldingWidth >
      //       minWidthForSingleWedge
      //     ) {
      //       middleWedge = "single";
      //     }
      //   }

      //   // ! if 3 stack, push line item into SubItems and add sku to Line.FrameSize.Layers.M2
      //   line.SubItems.push({
      //     SKU: orderData.selected.stacks.middleDetails.sku,
      //     Moulding: orderData.selected.stacks.middleDetails.sku,
      //     Layer: "2",
      //     Length: Math.trunc(orderData.selected.stacks.middleDetails.height),
      //     Width: Math.trunc(orderData.selected.stacks.middleDetails.width),
      //     LengthFraction:
      //       orderData.selected.stacks.middleDetails.height % 1 !== 0
      //         ? decimalString(orderData.selected.stacks.middleDetails.height)
      //         : undefined,
      //     WidthFraction:
      //       orderData.selected.stacks.middleDetails.width % 1 !== 0
      //         ? decimalString(orderData.selected.stacks.middleDetails.width)
      //         : undefined,
      //     Wedge: middleWedge,
      //   });

      //   line.FrameSize.Layers.M2 = orderData.selected.stacks.middleDetails.sku;
      // }

      // // Check if join is considered oversized for ground shipping
      // if (orderData.selected.stackOperation === JOIN) {
      //   const oversized = isJoinOversized(
      //     orderData.selected.stacks.outsideDetails.width,
      //     orderData.selected.stacks.outsideDetails.height,
      //     orderData.selected.sizing,
      //     orderData.selected.stacks.outsideDetails.mouldingWidth,
      //     orderData.selected.stacks.outsideDetails.mouldingHeight
      //   );

      //   if (oversized) {
      //     setOrderData("selected", "oversizeWarning", true);
      //   }
      // }

      // let insideWedge;
      // if (
      //   orderData.selected.wedgeLock === "TRUE" &&
      //   orderData.selected.stackOperation === CHOP
      // ) {
      //   if (
      //     orderData.selected.stacks.insideDetails.mouldingWidth >=
      //     minWidthForDoubleWedge
      //   ) {
      //     insideWedge = "double";
      //   } else if (
      //     orderData.selected.stacks.insideDetails.mouldingWidth >
      //     minWidthForSingleWedge
      //   ) {
      //     insideWedge = "single";
      //   }
      // }
      // // ! push inside moulding as last item in SubItems
      // line.SubItems.push({
      //   SKU: orderData.selected.stacks.insideDetails.sku,
      //   Moulding: orderData.selected.stacks.insideDetails.sku,
      //   Layer: "I",
      //   Length: Math.trunc(orderData.selected.stacks.insideDetails.height),
      //   Width: Math.trunc(orderData.selected.stacks.insideDetails.width),
      //   LengthFraction:
      //     orderData.selected.stacks.insideDetails.height % 1 !== 0
      //       ? decimalString(orderData.selected.stacks.insideDetails.height)
      //       : undefined,
      //   WidthFraction:
      //     orderData.selected.stacks.insideDetails.width % 1 !== 0
      //       ? decimalString(orderData.selected.stacks.insideDetails.width)
      //       : undefined,
      //   Wedge: insideWedge,
      // });

      // if (orderData.selected.sizing == "I") {
      //   line.SubItems.reverse();
      // }
      // ?? END ORIGINAL STACK LOGIC - KEEP UNTIL TESTED ABOVE
    }

    //! BACKORDER CONSENT:
    if (orderData.selected.productType.includes(PT.STACK)) {
      switch (orderData.selected.stackStockCheckoutStatus) {
        case ThresholdCheckout.backorder:
          setOrderData("selected", "showBackorderBox", true);
          break;
        case ThresholdCheckout.optionalBackorder:
          setOrderData("selected", "showCancelBackorderBox", true);
          break;
        case ThresholdCheckout.block:
          setOrderData("blockCheckout", true);
          return true;
      }
    } else {
      // Backorder
      if (
        stockThreshold(
          orderData.selected.shipsFromStockLevel - orderQtyInFeet(),
          orderData.currentProduct.Category,
          orderData.currentProduct.Discontinued //! TEST - this was changed from isDiscontinued, which shouldnt exist on currentProduct
        ).checkout === ThresholdCheckout.backorder &&
        ![PT.PHOTOFRAME, PT.GALLERYFRAME, PT.CORNERSAMPLE].includes(
          orderData.selected.productType as PT
        ) &&
        !orderData.isDiscontinued
      ) {
        setOrderData("selected", "showBackorderBox", true);
      } else {
        setOrderData("selected", "showBackorderBox", false);
      }
      // Optional Backorder (user can opt to cancel order rather than place on backorder)
      if (
        stockThreshold(
          orderData.selected.shipsFromStockLevel - orderQtyInFeet(),
          orderData.currentProduct.Category,
          orderData.currentProduct.Discontinued
        ).checkout === ThresholdCheckout.optionalBackorder &&
        ![PT.PHOTOFRAME, PT.GALLERYFRAME, PT.CORNERSAMPLE].includes(
          orderData.selected.productType as PT
        ) &&
        !orderData.isDiscontinued
      ) {
        setOrderData("selected", "showCancelBackorderBox", true);
      } else {
        setOrderData("selected", "showCancelBackorderBox", false);
      }
    }

    //! BLOCK DUE TO STOCK THRESHOLD RULES
    if (
      stockThreshold(
        orderData.selected.shipsFromStockLevel - orderQtyInFeet(),
        orderData.currentProduct.Category,
        orderData.currentProduct.Discontinued
      ).checkout === ThresholdCheckout.block
    ) {
      setOrderData("blockCheckout", true);
      return true;
    }

    //! FREIGHT CHARGE CONSENT:
    if (
      [PT.CONTRACT, PT.BOX].includes(orderData.selected.productType as PT) ||
      (orderData.selected.productType === PT.LENGTH &&
        orderData.selected.cuttingInstr === "full")
    ) {
      setOrderData("selected", "showFreightBox", true);
    }

    return line as OrderProductTypes;
  });

  let controller: AbortController;
  // Retrieve estimates when needed
  // TODO - refactor this resource -> use fetchAPI and deal with error handling better
  const [estimate] = createResource(
    () => item(),
    async (line) => {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();
      // when line property is a boolean, then item is not validated
      if (typeof line === "boolean") {
        return { calculated: false };
      }
      try {
        // await simulateApiError({ path: "/cart/estimate" });
        // TODO - convert to fetchAPI or custom function?
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/cart/estimate`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${session()?.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify([line]),
            signal: controller.signal,
          }
        );
        const data = await response.json();
        if (response.ok) {
          return {
            calculated: true,
            amount: data[0].Amount,
            quantity: data[0].Quantity,
            // plant: data[0].Plant,
            discount: data[0].Discount,
            currency: data[0].Currency,
          };
        } else {
          // console.log("DATA WHEN RESPONSE NOT OK", data)
          throw new Error(data.Code);
        }
      } catch (err: any) {
        if (import.meta.env.DEV) {
          // TODO - see what was triggering addError() frequently..
          console.log("Error caught in createResource: ", err);
        }

        // console.log("ERROR", err)
        const obj = {
          calculated: false,
          error: undefined,
        } as Record<string, any>;
        switch (err.message) {
          // case "INVALID_PRICING_REQUEST":
          //   obj.error = "something";
          default:
            obj.error = "Something went wrong - please try again.";
        }

        const e = checkError(err);

        // TODO - Sentry
        // ? TODO - this seemed to be firing on some plant change actions..see what going on.
        // addError(e, {
        //   severity: "critical",
        //   showDetails: true,
        //   title: "Pricing Error",
        //   message:
        //     "An error occurred while retrieiving pricing information for this item. Please try again later. If this error persists, please contact support. We apologize for the inconvenience",
        //   actions: [
        //     {
        //       label: "Contact Support",
        //       action: async () => {
        //         navigate("/support");
        //       },
        //     },
        //   ],
        // });

        return obj;
      }
    },
    {
      initialValue: {
        calculated: false,
        amount: 0,
        quantity: 0,
        // plant: undefined,
        discount: undefined,
        currency: undefined,
      },
      ssrLoadFrom: "initial",
    }
  );

  // const [addCart, { refetch: addToCart }] = createResource(async (_, info) => {
  //   if (!info.refetching) return;
  //   try {
  //     setCartError(undefined);
  //     const response = await fetch(
  //       `${import.meta.env.VITE_ROMA_API}/cart/${session()?.cart_id}/line`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${session()?.token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(item()),
  //       }
  //     );

  //     if (response.ok) {
  //       track(EventType.Event, {
  //         category: "add_to_cart",
  //         action: "Product Added",
  //         value: estimate.latest.amount,
  //         // @ts-expect-error
  //         currency: estimate.latest.currency,
  //         items: [
  //           {
  //             // item_id: orderData.selected.moulding,
  //             item_id: (item() as OrderProductTypes).SubItems.map(
  //               (sub) => sub.SKU
  //             ).join(","),
  //             item_name: orderData.selected.name,
  //             item_category: (item() as OrderProductTypes).Type,
  //             item_category2: orderData.selected.category,
  //             item_category3: orderData.selected.collection,
  //             price: estimate.latest.amount,
  //             quantity: 1,
  //           },
  //         ],
  //       });
  //       trackFBQ("AddToCart", {
  //         content_category: (item() as OrderProductTypes).Type,
  //         content_ids: (item() as OrderProductTypes).SubItems?.map(
  //           (sub) => sub.SKU
  //         ),
  //         currency: estimate.latest.currency,
  //         value: estimate.latest.amount,
  //       });
  //       setOrderData({ ...getDefaultStore() });
  //       navigate(`/checkout/${session()?.cart_id}`);
  //     } else {
  //       const error = await response.json();

  //       switch (error?.Code) {
  //         case "CREDIT_BLOCK":
  //         case "ACCOUNT_BLOCKED":
  //           setCartError(
  //             "We were unable to add this item to your cart as your Roma account is currently blocked."
  //           );
  //           break;
  //         default:
  //           setCartError(
  //             "An error occurred while adding this item to your cart."
  //           );
  //           break;
  //       }

  //       throw new Error(
  //         `Request failed with status code ${response.status}. Error: ${error.Message}`
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // });

  // ! TESTING ACTIONS

  const newAddCart = async (checkout: boolean) => {
    if (import.meta.env.DEV) {
      console.log("[OrderSummar.tsx] adding to cart: ", JSON.stringify(item));
    }
    try {
      const response = await addItemToCart(item() as OrderProductTypes);
      // TODO - Add in tracking fns
      if (checkout) {
        // navigate to checkout page...
        setOrderData({ ...getDefaultStore() });
        navigate(`/checkout/${session()?.cart_id}`);
      } else {
        setPanel({ mode: "cart", open: true });
      }
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[OrderSummary.tsx] caught addToCart error: ", err);
      }
      setCartError("An error occurred while adding this item to your cart.");
      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "Add to Cart Error",
        message:
          "An error occurred while adding this item to your cart. Please try again, and if this issue persists kindly contact customer support. We apologize for the inconvenience.",
        actions: [
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
          // TODO - Sentry - dispatch log with payload..
          // What other actions to take?
        ],
      });
      throw err;
    }
  };

  const newEditCart = async () => {
    if (import.meta.env.DEV) {
      console.log("[editLineItem]: editing cart ID: ", params.editCart);
    }

    try {
      const response = await editLineItem(
        params.editCart as string,
        item() as OrderProductTypes /* OPTIONAL CART ID */
      );
      // TODO ERROR HANDLING ?
      setPanel({ mode: "cart", open: true });
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[editLineItem] caught error: ", err);
      }
      setCartError("An error occurred while editing this item.");
      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "Edit Item Error",
        message:
          "An error occurred while updating this item in your cart. Please try again, and if this issue persists kindly contact customer support. We apologize for the inconvenience.",
        actions: [
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
          // TODO - Sentry - dispatch log with payload..
          // What other actions to take?
        ],
      });
      throw err;
    }
  };

  const numberWithCommas = (x: number): string => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <Show when={estimate.latest.error}>
        <hr />
        <InfoBox type="error">
          Something went wrong - please try again, or call Customer Care at{" "}
          <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
            1-800-263-2322
          </a>{" "}
          for support. We apologize for the inconvenience.
        </InfoBox>
      </Show>
      <Show when={cartError()}>
        <InfoBox type="error">
          <p>{cartError()}</p>
          <p>
            Please try again, or call Customer Care at{" "}
            <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
              1-800-263-2322
            </a>{" "}
            for support. We apologize for the inconvenience.
          </p>
        </InfoBox>
      </Show>
      <Show when={estimate.latest?.calculated === true}>
        <div class="border-t py-5 child:py-2">
          <Show when={Math.abs(estimate.latest.discount) > 0}>
            <div class="flex justify-between text-sm text-roma-dark-grey">
              <p>List Price</p>
              <p class="font-light line-through">
                $
                {(
                  Math.abs(estimate.latest.discount) + estimate.latest.amount
                ).toFixed(2)}
              </p>
            </div>
          </Show>
          <div class="flex justify-between text-lg font-bold ">
            <p>Your Price</p>
            <Show
              when={!estimate.loading}
              fallback={<BaseLoader width={5} height={5} />}
            >
              <p>${numberWithCommas(estimate.latest.amount.toFixed(2))}</p>
            </Show>
          </div>
          <Show
            when={orderData.editProduct}
            fallback={
              <div class="flex flex-col gap-2">
                <Button
                  disabled={
                    aCartActionIsPending() ||
                    estimate.loading ||
                    (orderData.selected.showBackorderBox &&
                      !orderData.selected.backorderConsent) ||
                    (orderData.selected.showFreightBox &&
                      !orderData.selected.freightChargeConsent) ||
                    (orderData.selected.showCancelBackorderBox &&
                      !orderData.selected.backorderConsent &&
                      !orderData.selected.cancelBackorderConsent)
                  }
                  class="w-full mt-4"
                  onClick={() => newAddCart(false)}
                >
                  <span class="tracking-wider">Add to cart</span>
                </Button>
                <Button
                  disabled={
                    aCartActionIsPending() ||
                    estimate.loading ||
                    (orderData.selected.showBackorderBox &&
                      !orderData.selected.backorderConsent) ||
                    (orderData.selected.showFreightBox &&
                      !orderData.selected.freightChargeConsent) ||
                    (orderData.selected.showCancelBackorderBox &&
                      !orderData.selected.backorderConsent &&
                      !orderData.selected.cancelBackorderConsent)
                  }
                  disabledClass="!bg-roma-medium-grey !text-white"
                  class="w-full "
                  onClick={() => newAddCart(true)}
                  style="outline"
                >
                  <span class="tracking-wider">Add to cart & Checkout</span>
                </Button>
              </div>
            }
          >
            <Button
              disabled={
                aCartActionIsPending() ||
                estimate.loading ||
                (orderData.selected.showBackorderBox &&
                  !orderData.selected.backorderConsent) ||
                (orderData.selected.showFreightBox &&
                  !orderData.selected.freightChargeConsent) ||
                (orderData.selected.showCancelBackorderBox &&
                  !orderData.selected.backorderConsent &&
                  !orderData.selected.cancelBackorderConsent)
              }
              class="w-full mt-4"
              onClick={() => {
                // updateLineItem();
                newEditCart();
              }}
            >
              <span class="tracking-wider">
                {estimate.loading ? "Loading" : "Update Order"}
              </span>
            </Button>
          </Show>
        </div>
      </Show>
    </>
  );
};
