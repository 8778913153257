import { Component, Show } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import {
  Dimensions,
  Allowance,
  QtyCounter,
  Orientation,
  JoinWarning,
  WedgeLock,
} from "../";

export const Chop: Component = () => {
  const { orderData } = useItemOrderContext();
  return (
    <>
      <Dimensions />
      <JoinWarning />
      <Show when={orderData.isStretcher}>
        <Orientation />
      </Show>
      <Allowance />
      <WedgeLock />
      <QtyCounter />
    </>
  );
};
