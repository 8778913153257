import { Component, onMount, useContext } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { StackProductSearch } from "./StackProductSearch";

export const StackSearchThree: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();

  onMount(() => {
    if (
      orderData.selected.moulding !== undefined &&
      orderData.selected.stacks.insideDetails.sku === undefined
    ) {
      setOrderData(
        "selected",
        "stacks",
        "insideDetails",
        "sku",
        orderData.selected.moulding
      );
      setOrderData(
        "selected",
        "stacks",
        "insideDetails",
        "mouldingWidth",
        orderData.width
      );
    }
  });

  return (
    <>
      <StackProductSearch
        label="Inside Frame"
        position="inside"
        preloadSku={orderData.selected.stacks.insideDetails.sku ?? undefined}
        storePath={["selected", "stacks", "insideDetails"]}
      />
      <StackProductSearch
        label="Middle Frame"
        position="middle"
        preloadSku={orderData.selected.stacks.middleDetails.sku ?? undefined}
        storePath={["selected", "stacks", "middleDetails"]}
      />
      <StackProductSearch
        label="Outside Frame"
        position="outside"
        preloadSku={orderData.selected.stacks.outsideDetails.sku ?? undefined}
        storePath={["selected", "stacks", "outsideDetails"]}
      />
    </>
  );
};
