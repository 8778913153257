import { Component, Show, onMount } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { A } from "@solidjs/router";
import { InfoBox } from "~/components/utility";
import { QtyCounter } from "../QtyCounter";

export const CornerSample: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  onMount(() => {
    setOrderData("selected", "quantity", 1);
    setOrderData("selected", "shipsFrom", orderData.defaultShipsFrom);
  });
  return (
    <>
      <QtyCounter disabled={true} />
      <Show when={true}>
        <InfoBox>
          <div>
            <p>
              Limit of 1 sample per order. Contact your Business Partner
              Specialist to order additional samples.
            </p>
            <A href="/account" class="text-roma-blue">
              View my partner profile
            </A>
          </div>
        </InfoBox>
        <Show when={orderData.currentProduct?.Category === "Roma Elite"}>
          <InfoBox>
            <div>
              <p>
                Roma Elite moulding samples require a special partner order and
                do not ship free. Contact your Business Partner Specialist for
                this request.
                <A href="/account" class="ml-1 text-roma-blue">
                  View my partner profile
                </A>
              </p>
            </div>
          </InfoBox>
        </Show>
      </Show>
    </>
  );
};
