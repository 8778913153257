import { Component, onCleanup, createRenderEffect } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { QtyCounter, PFGFSize } from "../";
import { PT } from "~/utils/products";

export const GalleryFrame: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  createRenderEffect(() => {
    if (!orderData.editProduct) setOrderData("selected", "quantity", 6);
  });
  onCleanup(() => {
    setOrderData("selected", "quantity", 1);
  });

  return (
    <>
      <PFGFSize type={PT.GALLERYFRAME} />
      <QtyCounter />
      <p class="text-sm text-roma-dark-grey font-light">
        &bull; Minimum quantity of 6 Gallery Frames required
      </p>
    </>
  );
};